import { isBrowser } from './isBrowser';

export const productUrl = (handle: string, type: string): string => {
  handle = handle.replace('products/', '');

  let hostname = process.env.NEXT_PUBLIC_HOSTNAME;
  if (isBrowser()) {
    hostname = `${window.location.origin}/`;
  }

  if (type === 'Collection') {
    return `${hostname}series/${handle}`;
  }
  if (type === 'NFT') {
    return `${hostname}nft/${handle}`;
  }

  return `${hostname}products/${handle}`;
};
