import { LightboxItem } from '../design-system/components/Lightbox/types';
import {
  GeneratedEventArteMedia,
  GeneratedEventArteMediaType,
} from '../types/generated';
import { AssetStoryblok } from '../types/generated-storyblok';

export const storyblokMediaItemToLightboxEntry = (
  item: StoryblokMediaItem,
  index: number,
) => {
  if ((item as any).image) {
    return storyblokImageToLightboxEntry(
      (item as any).image,
      (item as any).showCopyright,
      index,
    );
  } else if ((item as any).component === 'soundcloudContainer') {
    return soundcloudToLightboxEntry((item as any).soundCloud[0], index);
  } else {
    return storyblokVideoToLightboxEntry(
      (item as any).coverImage,
      (item as any).vimeo,
      (item as any).showCopyright,
      index,
    );
  }
};

export const storyblokImageToLightboxEntry = (
  image: AssetStoryblok,
  showCopyright: boolean,
  index: number,
): LightboxItem => {
  return {
    key: index,
    media: {
      type: 'IMAGE' as 'IMAGE',
      src: image.filename,
      alt: image.alt,
    },
    copyright: showCopyright ? image.copyright : undefined,
  };
};

const storyblokVideoToLightboxEntry = (
  coverImage: StoryblokImage,
  vimeo: LegacyVimeo,
  showCopyright: boolean,
  index: number,
): LightboxItem => {
  return {
    key: index,
    media: {
      type: 'VIMEO',
      id: vimeo?.vimeo_oembed?.response.video_id.toString(),
    },
    copyright: showCopyright ? coverImage.copyright : undefined,
  };
};

const soundcloudToLightboxEntry = (
  soundCloud: SoundCloud,
  index: number,
): LightboxItem => {
  return {
    key: index,
    media: {
      type: 'SOUNDCLOUD' as 'SOUNDCLOUD',
      id: String(soundCloud.trackId),
    },
  };
};

export const eventArteMediaItemToLightboxEntry = (
  item: GeneratedEventArteMedia,
  index: number,
) => {
  if (item.mediaType === GeneratedEventArteMediaType.Video) {
    return {
      key: index,
      media: {
        type: 'VIMEO',
        id: item.url.split('.com/')[1],
      },
      copyright: undefined,
    };
  } else {
    return {
      key: index,
      media: {
        type: 'IMAGE',
        src: item.url,
        alt: '',
      },
    };
  }
};
