import classNames from 'classnames';
import React from 'react';

import { Icon } from '../Icon';

export interface AlertProps {
  $dark?: boolean;
  $status: 'error' | 'success' | 'info';
  className?: string;
}

const getThemeColor = (status: AlertProps['$status'], dark = false) => {
  switch (status) {
    case 'success':
      if (dark)
        return {
          icon: 'text-positive-3',
          text: 'text-white',
          background: 'bg-positive-6',
        } as const;
      return {
        icon: 'text-positive-4',
        text: 'text-neutral-6',
        background: 'bg-positive-1',
      } as const;
    case 'error':
      if (dark)
        return {
          icon: 'text-active-3',
          text: 'text-white',
          background: 'bg-active-6',
        } as const;
      return {
        icon: 'text-active-4',
        text: 'text-neutral-6',
        background: 'bg-active-1',
      } as const;
    default:
      if (dark)
        return {
          icon: 'text-neutral-3',
          text: 'text-white',
          background: 'bg-neutral-5',
        } as const;
      return {
        icon: 'text-neutral-4',
        text: 'text-neutral-6',
        background: 'bg-neutral-1',
      } as const;
  }
};

export const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
  $dark = false,
  $status,
  children,
  className,
}) => (
  <div
    className={classNames(
      'flex items-top rounded-sm p-3',
      getThemeColor($status, $dark).background,
      getThemeColor($status, $dark).text,
      className,
    )}
  >
    <div className="shrink-0">
      {$status === 'error' && (
        <Icon
          className={classNames(getThemeColor($status, $dark).icon, 'mr-3')}
          name="ic_error"
          size="sm"
        />
      )}
      {$status === 'success' && (
        <Icon
          className={classNames(getThemeColor($status, $dark).icon, 'mr-3')}
          name="ic_check_circle"
          size="sm"
        />
      )}
      {$status === 'info' && (
        <Icon
          className={classNames(getThemeColor($status, $dark).icon, 'mr-3')}
          name="ic_info"
          size="sm"
        />
      )}
    </div>
    <span className="break-words whitespace-normal">{children}</span>
  </div>
);
