import classNames from 'classnames';
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Image } from './Image';
import type { VimeoPlugin } from '../types/Vimeo';
import { AssetStoryblok } from '../types/generated-storyblok';

type Props = {
  className?: HTMLAttributes<HTMLDivElement>['className'];
  cover?: AssetStoryblok;
  vimeo: VimeoPlugin;
};

const BackgroundVideo: React.FC<Props> = ({ className, cover, vimeo }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<string | null>(null);
  const [height, setHeight] = useState<string | null>(null);

  const updateDimensions = useCallback(() => {
    if (containerRef.current === null) return;

    const video = vimeo?.vimeo_oembed?.response;
    if (!video) return;

    // Calculate the aspect ratios of both the container element,
    // and the video itself.
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerAspectRatio = containerRect.width / containerRect.height;
    const videoAspectRatio = video.width / video.height;

    // When the video aspect ratio matches the containers,
    // make the Vimeo player full width and full height
    if (containerAspectRatio === videoAspectRatio) {
      setWidth('100%');
      setHeight('100%');
      return;
    }

    // When the video aspect ratio is wider than the containers,
    // make the Vimeo player full height and too wide
    if (containerAspectRatio < videoAspectRatio) {
      const factor = videoAspectRatio / containerAspectRatio;
      setHeight('100%');
      setWidth(`${100 * factor}%`);
      return;
    }

    // When the video aspect ratio is narrower than the containers,
    // make the Vimeo player full width and too tall
    if (containerAspectRatio > videoAspectRatio) {
      const factor = videoAspectRatio / containerAspectRatio;
      setHeight(`${100 / factor}%`);
      setWidth('100%');
      return;
    }
  }, [containerRef, vimeo]);

  useEffect(() => {
    if (containerRef.current === null) return;

    // Update video dimensions when resizing the window
    const observer = new ResizeObserver(() => {
      updateDimensions();
    });

    observer.observe(containerRef.current);

    // Set initial video dimensions immediately
    updateDimensions();

    return () => observer.disconnect();
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={classNames(
        'relative w-full h-full flex justify-center items-center',
        className,
      )}
    >
      <div
        className={classNames('absolute top-0 left-0 right-0 bottom-0', {
          'skeleton': !cover?.filename,
        })}
      >
        {cover && (
          <Image
            alt={cover?.alt || ''}
            className="h-full"
            eagerLoading
            focus={cover.focus}
            size={{
              sm: { ratio: '1/1', span: 12 },
              md: { ratio: '1/1', span: 6 },
              lg: { ratio: '1/1', span: 4 },
            }}
            src={cover.filename}
          />
        )}
      </div>
      {width !== null && height !== null && (
        <iframe
          src={`https://player.vimeo.com/video/${vimeo?.vimeo_oembed?.response?.video_id}?background=1&autoplay=1&autopause=0`}
          className="absolute scale-105 pointer-events-none"
          allow="autoplay"
          frameBorder="0"
          width={width}
          height={height}
        />
      )}
    </div>
  );
};

export default BackgroundVideo;
