import classNames from 'classnames';

import { Image } from '../../../../components/Image';

export type ArtistListProps = {
  image: StoryblokImage;
  name: string;
  overline: string;
};

export const ArtistList: React.FC<ArtistListProps> = ({
  image,
  name,
  overline,
}) => {
  return (
    <div
      className={classNames(
        'w-full flex justify-center items-center gap-x-3 overflow-hidden',
        classNames,
      )}
    >
      <div className="shrink-0 w-16">
        <Image
          alt={image.alt}
          focus={image.focus}
          hoverEffect
          shape="rounded"
          src={image.filename}
          width={64}
        />
      </div>

      <div className="w-full flex flex flex-col overflow-hidden group-hover:text-neutral-4 transition duration-300 ease-out">
        <p className="font-bold truncate">{name}</p>
        <p className="truncate">{overline}</p>
      </div>
    </div>
  );
};
