/* eslint-disable react/no-array-index-key */
// import '@splidejs/react-splide/css/core';
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { LayoutTheme } from '../../../../components/Layout/types';
import styles from '../../Button/button.module.css';
import { Icon } from '../../Icon';

type Props = Options & {
  headline?: string | null | undefined;
  items: Array<React.ReactNode>;
  gap?: 3 | 5;
  perPage?: number;
  arrowPosition?: 'top' | 'middle';
  arrowTheme?: LayoutTheme;
  arrowSize?: 'large' | 'default';
  arrowWrapperClassName?: string;
  onSlide?: (index: number) => void;
  resetTrigger?: number;
  className?: string;
  slideClassName?: string;
};

export const Carousel = ({
  arrowPosition = 'top',
  arrowSize,
  arrowTheme = 'light',
  arrowWrapperClassName,
  autoWidth = false,
  breakpoints,
  className,
  focus,
  gap = 5,
  grid,
  headline,
  items,
  onSlide = () => {},
  perPage = 4,
  resetTrigger,
  rewind = true,
  slideClassName,
  trimSpace = true,
}: Props) => {
  const mainRef = React.useRef<Splide>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const resetSplide = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.go(0);
      setSelectedIndex(0);
    }
  }, [mainRef]);

  useEffect(() => {
    resetSplide();
  }, [resetTrigger, resetSplide]);

  useEffect(() => {
    mainRef.current?.splide?.destroy();
    mainRef.current?.splide?.mount();
    setSelectedIndex(0);
    mainRef.current?.splide?.on('moved', (index) => {
      onSlide(index);
      setSelectedIndex(index);
    });
  }, [grid]);

  if (items.length === 0) return null;

  const getPerPageForBreakpoint = (
    currentPerPage: number,
    breakpoint: number,
  ) => {
    if (breakpoint === 1024) {
      return currentPerPage > 2 ? 3 : currentPerPage;
    }
    if (breakpoint === 768) {
      return currentPerPage > 2 ? 2 : currentPerPage;
    }
    return currentPerPage;
  };

  const paginationArrows = (
    <>
      <button
        className={classNames(
          styles.UiButton__variant__tertiary,
          'splide__arrow splide__arrow--prev !rounded-full z-50',
          arrowSize === 'large' ? 'p-3' : 'p-2',
          {
            'opacity-0 invisible': !rewind && selectedIndex === 0,
          },
        )}
        type="submit"
      >
        <Icon name="ic_arrow_left" />
      </button>
      <button
        className={classNames(
          styles.UiButton__variant__tertiary,
          'splide__arrow splide__arrow--next !rounded-full z-50',
          arrowSize === 'large' ? 'p-3' : 'p-2',
          {
            'opacity-0 invisible':
              !rewind && selectedIndex === items.length - 1,
          },
        )}
        type="submit"
      >
        <Icon name="ic_arrow_right" />
      </button>
    </>
  );

  return (
    <div
      className={classNames(className)}
      id={grid !== undefined ? 'grid-carousel' : ''}
    >
      <Splide
        ref={mainRef}
        extensions={{ Grid }}
        hasTrack={false}
        onMoved={(e) => {
          onSlide(e.index);
          setSelectedIndex(e.index);
        }}
        options={{
          grid,
          label: 'carousel',
          rewind,
          pagination: false,
          mediaQuery: 'min',
          autoWidth,
          trimSpace,

          arrows: false,
          focus,
          gap: 12,
          perMove: 1,
          perPage: perPage || 2,

          flickPower: 300,
          flickMaxPages: 0.3,

          breakpoints: {
            768: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 768),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 768),
              perPage: getPerPageForBreakpoint(perPage, 768),
              ...breakpoints?.[768],
            },
            1024: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 1024),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 1024),
              perPage: getPerPageForBreakpoint(perPage, 1024),
              ...breakpoints?.[1024],
            },
            1280: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 1280),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 1280),
              perPage: getPerPageForBreakpoint(perPage, 1280),
              ...breakpoints?.[1280],
            },
          },
        }}
      >
        {headline && (
          <div className="w-full flex justify-between space-x-3 mb-4">
            <h2 className="text-md md:text-lg text-neutral-4 relative z-20">
              {headline}
            </h2>

            {arrowPosition === 'top' && (
              <div
                className={classNames(
                  'splide__arrows flex justify-center items-center space-x-3',
                  { 'theme-dark': arrowTheme === 'dark' },
                  arrowWrapperClassName,
                )}
              >
                {paginationArrows}
              </div>
            )}
          </div>
        )}

        <div className="relative">
          <div className="relative">
            {arrowPosition === 'middle' && (
              <div
                className={classNames(
                  'splide__arrows absolute w-full h-full top-0 right-0 left-0 bottom-0 flex justify-between items-center',
                  { 'theme-dark': arrowTheme === 'dark' },
                  arrowWrapperClassName,
                )}
              >
                {paginationArrows}
              </div>
            )}
            <SplideTrack>
              {items.map((item, index) => (
                <SplideSlide
                  key={index}
                  className={classNames('w-full', slideClassName)}
                >
                  <div className="w-full h-full flex flex-col">{item}</div>
                </SplideSlide>
              ))}
            </SplideTrack>
          </div>
          <ul
            aria-label="Select a slide to show"
            className="splide__pagination splide__pagination--ltr flex justify-center relative b-0 py-5 gap-1.5"
            role="tablist"
          />
        </div>
      </Splide>
    </div>
  );
};
