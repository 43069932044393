import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [wrapper] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(wrapper);
  }, []);

  return ReactDOM.createPortal(children, wrapper);
};

export default Portal;
