import classNames from 'classnames';
import React from 'react';

import styles from './field.module.css';

export interface FieldWrapperProps {
  className?: string;
  $id?: string;
  $label?: string;
  $error?: string;
  $disabled?: boolean;
  $showError?: boolean;
  children?: React.ReactNode;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  $disabled,
  $error,
  $id,
  $label,
  $showError = true,
  children,
  className,
}) => (
  <div className={classNames(styles.UiField, className)}>
    {$label ? (
      <label className={classNames(styles.UiField__label)} htmlFor={$id}>
        {$label}
      </label>
    ) : null}

    <div
      className={classNames(
        styles.UiField__field,
        $showError && $error && styles.UiField__field__error,
        $disabled && styles.UiField__field__disabled,
      )}
    >
      {children}
    </div>

    {$showError && $error ? (
      <p className={classNames(styles.UiField__error)}>{$error}</p>
    ) : null}
  </div>
);
