import classNames from 'classnames';
import NextImage from 'next/legacy/image';
import React, { useMemo } from 'react';

import { PLACE_HOLDER_IMAGE } from '../../constants';
import {
  imageLoader,
  ImageSizes,
  imageSizes,
  storyblokImagePosition,
} from '../../helpers/images';
import { AspectRatio } from '../AspectRatio/AspectRatio';

export type ImageProps = {
  alt: string;
  className?: string;
  eagerLoading?: boolean;
  focus?: string | null;
  hoverEffect?: boolean;
  ignoreAspectRatio?: boolean;
  quality?: number;
  onClick?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  shape?: 'circle' | 'rounded' | 'square';
  size?: ImageSizes;
  src: string;
  // https://nextjs.org/docs/pages/api-reference/components/image-legacy#image-sizes
  width?: 16 | 32 | 48 | 64 | 96 | 128 | 256 | 384;
  unoptimized?: boolean;
};

export const Image: React.FC<ImageProps> = ({
  alt,
  className,
  eagerLoading = false,
  focus,
  hoverEffect = false,
  ignoreAspectRatio = false,
  onClick,
  onError,
  onLoad,
  quality,
  shape = 'square',
  size = { sm: { ratio: '1/1', span: 12 } },
  src = PLACE_HOLDER_IMAGE,
  unoptimized = false,
  width,
}) => {
  const position = useMemo(
    () => storyblokImagePosition(src, focus),
    [focus, src],
  );

  const sizes = useMemo(
    () => (width ? `${width}px` : imageSizes(size)),
    [size, width],
  );

  const image = (
    <NextImage
      alt={alt}
      className={classNames(
        'image w-full md:w-full lg:w-full overflow-hidden',
        {
          'image-intrinsic': size.sm?.ratio === 'intrinsic',
          'scale-100 group-hover:scale-105 transition duration-300 ease-out':
            hoverEffect,
        },
      )}
      layout="fill"
      loader={imageLoader}
      objectFit="cover"
      objectPosition={position}
      onError={onError}
      onLoadingComplete={onLoad}
      placeholder="empty"
      priority={eagerLoading}
      quality={quality}
      sizes={sizes}
      src={src}
      title={alt}
      unoptimized={unoptimized}
    />
  );

  if (ignoreAspectRatio) return image;

  return (
    <AspectRatio
      className={classNames('bg-neutral-1 overflow-hidden', className, {
        'image-intrinsic-container': size.sm?.ratio === 'intrinsic',
        'rounded-sm': shape === 'rounded',
        'rounded-full': shape === 'circle',
      })}
      lg={size.lg?.ratio}
      md={size.md?.ratio}
      onClick={onClick}
      sm={size.sm?.ratio}
    >
      {image}
    </AspectRatio>
  );
};
