import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';

import { Image } from '../../../../components/Image';
import { ImageSizes } from '../../../../helpers/images';
import { storyblokMediaItemToLightboxEntry } from '../../../../utils/lightbox';
import styles from '../../Button/button.module.css';
import { Icon } from '../../Icon';
import { Lightbox } from '../../Lightbox';
import { MediaContainer } from '../../MediaContainer';

type Props = {
  images: MultiMediaCarouselItem[];
  onClick?: (properties: Record<string, string | number>) => void;
  onSlide?: (index: number) => void;
  resetTrigger?: number;
};

export const ArtistImageGallery: React.FC<Props> = ({
  images,
  onClick,
  onSlide = () => {},
  resetTrigger,
}) => {
  const lightboxItems = React.useMemo(
    () => images.map(storyblokMediaItemToLightboxEntry),
    [images],
  );

  const mainRef = React.useRef<Splide>(null);
  const resetSplide = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.go(0);
    }
  }, [mainRef]);

  useEffect(() => {
    resetSplide();
  }, [resetTrigger, resetSplide]);

  useEffect(() => {
    resetSplide();
  });

  return (
    <Lightbox items={lightboxItems}>
      {(lightbox) => (
        <Splide
          ref={mainRef}
          hasTrack={false}
          onMoved={(e) => {
            onSlide(e.index);
          }}
          options={{
            label: 'artist-image-gallery',
            rewind: true,
            pagination: false,
            mediaQuery: 'min',

            arrows: false,
            focus: 'center',
            gap: 12,
            perMove: 1,
            perPage: 2,

            flickPower: 300,
            flickMaxPages: 0.3,

            breakpoints: {
              768: {
                arrows: images.length > 1,
                gap: 20,
                perPage: 1,
              },
            },
          }}
        >
          <div className="relative">
            <div
              className={classNames(
                'splide__arrows',
                'absolute inset-0 flex justify-between items-center px-4',
              )}
            >
              <button
                className={classNames(
                  styles.UiButton__variant__tertiary,
                  'p-2',
                  'splide__arrow splide__arrow--prev !rounded-full z-50',
                )}
                type="submit"
              >
                <Icon name="ic_arrow_left" />
              </button>
              <button
                className={classNames(
                  styles.UiButton__variant__tertiary,
                  'p-2',
                  'splide__arrow splide__arrow--next !rounded-full z-50',
                )}
                type="submit"
              >
                <Icon name="ic_arrow_right" />
              </button>
            </div>

            <SplideTrack>
              {images.map((media, index) => {
                const sizes = {
                  sm: { ratio: '1/1', span: 12 },
                  md: { ratio: '3/2', span: 6 },
                  lg: { ratio: '3/2', span: 8 },
                } as ImageSizes;

                if (media.component === 'image') {
                  return (
                    <SplideSlide key={media._uid}>
                      <div className="w-full">
                        <MediaContainer
                          mediaComponent={
                            <Image
                              alt={media.image.alt}
                              eagerLoading={index === 0}
                              focus={media.image.focus}
                              onClick={() => {
                                lightbox.openOnSlide(index);
                                onClick?.({
                                  position: index,
                                  title: media.image.title,
                                  type: 'image',
                                });
                              }}
                              shape="rounded"
                              size={sizes}
                              src={media.image.filename}
                            />
                          }
                          type="Image"
                        />
                      </div>
                    </SplideSlide>
                  );
                }

                return (
                  <SplideSlide key={media._uid}>
                    <MediaContainer
                      mediaComponent={
                        <Image
                          alt={media.coverImage?.alt}
                          eagerLoading={index === 0}
                          focus={media.coverImage.focus}
                          onClick={() => {
                            lightbox.openOnSlide(index);
                            onClick?.({
                              position: index,
                              title: media.coverImage?.title,
                              type: 'video',
                            });
                          }}
                          shape="rounded"
                          size={sizes}
                          src={media.coverImage?.filename}
                        />
                      }
                      type="Video"
                    />
                  </SplideSlide>
                );
              })}
            </SplideTrack>
          </div>
        </Splide>
      )}
    </Lightbox>
  );
};
