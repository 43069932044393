import classNames from 'classnames';

import { IconType } from '../../types';
import { Icon, PulsingDot } from '../Icon';

type Props = {
  $isActive?: boolean;
  $isDark?: boolean;
  $isLoading?: boolean;
  className?: string;
  label: React.ReactNode | string;
  icon?: IconType;
};

export const Pill: React.FC<Props> = ({
  $isActive,
  $isDark = false,
  $isLoading = false,
  className,
  icon,
  label,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-center items-center p-1 md:p-2 rounded-full',
        className,
        $isDark
          ? 'text-white bg-white/10 ring-1 ring-white/10 backdrop-blur-sm'
          : 'text-neutral-6 bg-light ring-1 ring-divider/20 theme-dark:bg-white/10 theme-dark:ring-1 theme-dark:ring-white/10 theme-dark:backdrop-blur-sm',
      )}
    >
      {$isActive ? <PulsingDot isDark={$isDark} /> : null}
      {icon ? <Icon name={icon} /> : null}
      {$isLoading ? (
        <span className="h-5 w-32 skeleton mx-1" />
      ) : (
        <span className="px-1 text-center first-letter:uppercase">{label}</span>
      )}
    </div>
  );
};
