import { FC } from 'react';

import { Image } from '../../../components/Image';

export interface Props {
  email: string;
  src?: string | null;
  userName?: string | null;
}

export const Avatar: FC<Props> = ({ email, src, userName = '' }) => (
  <div className="ring-2 ring-divider/20 bg-neutral-1 rounded-full flex items-center justify-center overflow-hidden h-9 w-9">
    {src ? (
      <Image alt={userName || email} src={src} width={32} />
    ) : (
      <p className="flex items-center justify-center">
        {(userName || email)?.replace(/^\W+/, '')[0]?.toUpperCase()}
      </p>
    )}
  </div>
);
