import React from 'react';

import { Button } from '../Button';
import { PageNumbers } from './PageNumbers';

type Props = {
  numPages: number;
  currentPage: number;
  paginationUrl: string;
  queryParams?: string;
  handleOnClick?: (page: number) => void;
};

export const Pagination: React.FC<Props> = ({
  currentPage,
  handleOnClick,
  numPages,
  paginationUrl,
  queryParams,
}) => {
  const isPrevButtonDisabled = currentPage === 1;
  const isNextButtonDisabled = currentPage === numPages;

  return (
    <div className="w-full flex justify-center items-center space-x-4">
      <Button
        as="a"
        hideLabel
        href={
          isPrevButtonDisabled
            ? '#'
            : `${paginationUrl}/${currentPage - 1}${queryParams}`
        }
        icon="ic_arrow_left"
        label="Previous page"
        onClick={() => handleOnClick?.(currentPage - 1)}
        size="sm"
        variant="tertiary"
      />
      <div className="shrink-0 flex justify-center items-center">
        <PageNumbers
          currentPage={currentPage}
          handleOnClick={handleOnClick}
          numPages={numPages}
          paginationUrl={paginationUrl}
          queryParams={queryParams}
        />
      </div>
      <Button
        as="a"
        hideLabel
        href={
          isNextButtonDisabled
            ? '#'
            : `${paginationUrl}/${currentPage + 1}${queryParams}`
        }
        icon="ic_arrow_right"
        label="Next page"
        onClick={() => handleOnClick?.(currentPage + 1)}
        size="sm"
        variant="tertiary"
      />
    </div>
  );
};
