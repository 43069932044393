import {
  getFirstStoryblokParagraph,
  isStoryblokRichTextPresent,
} from '@utils/renderStoryblokRichText';
import classNames from 'classnames';
import { render } from 'storyblok-rich-text-react-renderer-ts';

import { Image } from '../../../../components/Image';
import { RichtextStoryblok } from '../../../../types/generated-storyblok';

export type ArtistCardProps = {
  image: StoryblokImage;
  name: string;
  description?: RichtextStoryblok;
  overline: string;
};

const renderArtistDescription = (
  description: ArtistCardProps['description'],
) =>
  isStoryblokRichTextPresent(description) ? (
    <p className="text-neutral-4 line-clamp-3">
      {render(getFirstStoryblokParagraph(description), {
        markResolvers: {
          link: (children) => <>{children}</>,
        },
        nodeResolvers: {
          paragraph: (children) => <>{children}</>,
        },
      })}
    </p>
  ) : null;

export const ArtistCard: React.FC<ArtistCardProps> = ({
  description,
  image,
  name,
  overline,
}) => {
  return (
    <div
      className={classNames(
        'w-full flex flex-col overflow-hidden rounded-sm border border-divider/20',
        classNames,
      )}
    >
      <div className="w-full">
        <Image
          alt={image.alt}
          focus={image.focus}
          hoverEffect
          size={{
            sm: { ratio: '4/3', span: 12 },
            md: { ratio: '4/3', span: 6 },
            lg: { ratio: '4/3', span: 4 },
          }}
          src={image.filename}
        />
      </div>

      <div className="flex flex-col gap-3 p-4 md:p-6 group-hover:text-neutral-4 transition duration-300 ease-out">
        <div className="flex flex-col">
          <p className="font-bold">{name}</p>
          <p>{overline}</p>
        </div>

        {renderArtistDescription(description)}
      </div>
    </div>
  );
};
