import classNames from 'classnames';
import type React from 'react';

interface Props {
  className?: string;
}

export const AvantArteLogo: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={classNames('w-auto h-14', className)}
      fill="none"
      viewBox="0 0 360 360"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Avant Arte logo</title>
      <path
        clipRule="evenodd"
        d="M211.505 123.734C204.929 112.776 192.975 106 180 106C167.025 106 155.071 112.776 148.495 123.734L103.124 199.334C96.4246 210.498 96.286 224.35 102.758 235.642C109.239 246.949 121.392 254 134.63 254H225.37C238.608 254 250.761 246.949 257.242 235.642C263.714 224.35 263.575 210.498 256.876 199.334L211.505 123.734ZM225.224 115.501C215.735 99.6885 198.553 90 180 90C161.447 90 144.265 99.6885 134.776 115.501L89.4054 191.101C79.724 207.232 79.5222 227.278 88.8769 243.599C98.2316 259.919 115.702 270 134.63 270H225.37C244.298 270 261.768 259.919 271.123 243.599C280.478 227.278 280.276 207.232 270.595 191.101L225.224 115.501Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
