import classNames from 'classnames';
import { FC } from 'react';

import { Ratio } from '../../helpers/images';

const prefixAspectRatio = (prefix: 'lg:' | 'md:' | '', ratio?: Ratio) => {
  if (ratio === 'intrinsic') {
    return `${prefix}aspect-none`;
  }
  const [width, height] = String(ratio).split('/');
  if (width && height) {
    return `${prefix}aspect-w-${width} ${prefix}aspect-h-${height}`;
  }
  return '';
};

interface AspectRatioProps {
  className?: string;
  lg?: Ratio;
  md?: Ratio;
  onClick?: () => void;
  sm?: Ratio;
  children?: React.ReactNode;
}

export const AspectRatio: FC<AspectRatioProps> = ({
  children,
  className,
  lg,
  md,
  onClick,
  sm,
}) => {
  const classes = classNames(
    'block relative w-full',
    prefixAspectRatio('lg:', lg),
    prefixAspectRatio('md:', md),
    prefixAspectRatio('', sm),
    className,
  );

  if (typeof onClick === 'function') {
    return (
      <button className={classes} onClick={onClick} type="button">
        {children}
      </button>
    );
  }

  return <div className={classes}>{children}</div>;
};
