import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';

import { ButtonSize, ButtonVariant, IconType } from '../../types';
import { Icon, Spinner } from '../Icon';
import styles from './button.module.css';

interface ButtonBase {
  label: string;
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
}

type ButtonLabelIcon = ButtonBase & {
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  hideLabel?: boolean;
};

type ButtonIcon = ButtonBase & {
  icon: IconType;
  iconPosition?: 'right';
  hideLabel?: true;
};

type ButtonPattern = ButtonLabelIcon | ButtonIcon;

type HrefInterface = React.HTMLAttributes<HTMLAnchorElement> &
  ButtonPattern & {
    as?: 'a';
    href: string;
    target?: '_blank';
    rel?: 'noopener noreferrer';
  };

type ButtonInterface = React.HTMLAttributes<HTMLButtonElement> &
  ButtonPattern & {
    as?: 'button';
    type?: 'button' | 'submit';
    onClick?: () => void;
    disabled?: boolean;
  };

export type ButtonProps = HrefInterface | ButtonInterface;

const isHref = (props: ButtonProps): props is HrefInterface => {
  return props.as === 'a';
};

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const innerChildren = (
    <>
      {!props.hideLabel ? (
        <span className={classNames({ 'opacity-0': props.loading }, 'px-1')}>
          {props.label}
        </span>
      ) : null}
      {props.icon ? (
        <Icon
          className={classNames({ 'opacity-0': props.loading })}
          name={props.icon}
        />
      ) : null}
      {props.loading ? (
        <div className="absolute inset-0 flex justify-center items-center">
          <Spinner />
        </div>
      ) : null}
    </>
  );

  const sizeMapper = {
    'xs': 'p-1',
    'sm': 'p-2',
    'md': 'p-3',
    'lg': 'p-4',
  };

  if (isHref(props)) {
    const {
      fullWidth = false,
      hideLabel = false,
      href,
      icon,
      iconPosition = 'right',
      size = 'lg',
      variant = 'primary',
    } = props;

    return (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        {...(props.hideLabel && { 'aria-label': props.label })}
        className={classNames(
          variant === 'primary' && styles.UiButton__variant__primary,
          variant === 'secondary' && styles.UiButton__variant__secondary,
          variant === 'tertiary' && styles.UiButton__variant__tertiary,
          variant === 'transparent' && styles.UiButton__variant__transparent,
          variant === 'positive' && styles.UiButton__variant__positive,
          variant === 'danger' && styles.UiButton__variant__danger,
          sizeMapper[size],
          icon && hideLabel ? 'rounded-full' : 'rounded-sm',
          {
            'flex-row-reverse': iconPosition === 'left',
            'w-full': fullWidth,
          },
        )}
        href={href}
      >
        {innerChildren}
      </a>
    );
  }

  const {
    disabled,
    fullWidth = false,
    hideLabel = false,
    icon,
    iconPosition = 'right',
    loading = false,
    onClick,
    size = 'lg',
    type,
    variant = 'primary',
  } = props;

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      {...(props.hideLabel && { 'aria-label': props.label })}
      className={classNames(
        variant === 'primary' && styles.UiButton__variant__primary,
        variant === 'secondary' && styles.UiButton__variant__secondary,
        variant === 'tertiary' && styles.UiButton__variant__tertiary,
        variant === 'transparent' && styles.UiButton__variant__transparent,
        variant === 'positive' && styles.UiButton__variant__positive,
        variant === 'danger' && styles.UiButton__variant__danger,
        sizeMapper[size],
        icon && hideLabel ? 'rounded-full' : 'rounded-sm',
        {
          'cursor-not-allowed': disabled || loading,
          'opacity-50':
            disabled && variant !== 'positive' && variant !== 'danger',
          'flex-row-reverse': iconPosition === 'left',
          'w-full': fullWidth,
        },
      )}
      disabled={disabled || loading}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {innerChildren}
    </button>
  );
});
