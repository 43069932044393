import React from 'react';

type Props = {
  handleOnClick?: (index: number) => void;
  isCurrentPage: boolean;
  page: number;
  paginationUrl: string;
  queryParams?: string;
};

export const PageNumber: React.FC<Props> = ({
  handleOnClick,
  isCurrentPage,
  page,
  paginationUrl,
  queryParams,
}) => (
  <>
    {isCurrentPage ? (
      <div className="flex justify-center items-center text-center h-9 w-9">
        <p className="text-neutral-4">{page}</p>
      </div>
    ) : (
      <a
        className="hidden sm:flex justify-center items-center text-center h-9 w-9 hover:text-neutral-4 transition-colors ease-out duration-300"
        href={`${paginationUrl}/${page}${queryParams}`}
        onClick={() => handleOnClick?.(page)}
        onKeyDown={() => handleOnClick?.(page)}
        role="button"
        tabIndex={0}
      >
        {page}
      </a>
    )}
  </>
);
