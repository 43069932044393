import classNames from 'classnames';
import React from 'react';

import { icons } from '../../constants/icons';
import { IconType, TypographyVariant } from '../../types';

interface Props {
  name: IconType;
  size?: TypographyVariant;
  className?: string;
}

export const Icon: React.FC<Props> = ({ className, name, size = 'sm' }) => {
  const variantMapper = {
    xl: 'w-12 h-12',
    lg: 'w-9 h-9',
    md: 'w-6 h-6',
    sm: 'w-5 h-5',
    xs: 'w-4 h-4',
  };

  return (
    <div
      aria-hidden="true"
      className={classNames('block', variantMapper[size], className)}
    >
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d={icons[name]} fill="currentColor" />
      </svg>
    </div>
  );
};
