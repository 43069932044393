import classNames from 'classnames';
import { FC } from 'react';

interface VideoDurationProps {
  className?: string;
  duration: string;
}

export const VideoDuration: FC<VideoDurationProps> = ({
  className = 'absolute right-4 top-4',
  duration,
}) => (
  <span
    className={classNames(
      'bg-dark/50 backdrop-filter backdrop-blur pointer-events-none py-1 px-2 rounded-sm text-light',
      'opacity-100 group-hover:opacity-0 transition duration-300 ease-out',
      className,
    )}
  >
    {duration}
  </span>
);
