/* eslint-disable react/no-array-index-key */
// import '@splidejs/react-splide/css/core';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';

type Props = {
  items: Array<React.ReactNode>;
  resetTrigger?: number;
  className?: string;
  onSlide?: (index: number) => void;
  gridCols?: 3 | 4;
};

export const GridCarousel: React.FC<Props> = ({
  className,
  gridCols = 4,
  items,
  onSlide = () => {},
  resetTrigger,
}) => {
  const mainRef = React.useRef<Splide>(null);
  const resetSplide = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.go(0);
    }
  }, [mainRef]);

  useEffect(() => {
    resetSplide();
  }, [resetTrigger, resetSplide]);

  useEffect(() => {
    resetSplide();
  });

  const slideClasses = classNames(
    gridCols === 3
      ? 'md:col-span-6 lg:col-span-4'
      : 'md:col-span-4 lg:col-span-3',
  );

  return (
    <div className={classNames(className)}>
      <Splide
        ref={mainRef}
        hasTrack={false}
        onMoved={(e) => {
          onSlide(e.index);
        }}
        options={{
          label: 'grid-carousel',
          rewind: true,
          pagination: false,
          mediaQuery: 'min',

          arrows: false,
          focus: 0,
          gap: 12,
          perMove: 1,
          perPage: 2,

          flickPower: 300,
          flickMaxPages: 0.3,
          dragMinThreshold: {
            mouse: 10,
            touch: 30,
          },

          breakpoints: {
            768: {
              destroy: true,
            },
          },
        }}
      >
        <div className="relative">
          <SplideTrack>
            {items.map((item, index) => (
              <SplideSlide key={index} className={slideClasses}>
                <div className="w-full">{item}</div>
              </SplideSlide>
            ))}
          </SplideTrack>
        </div>
      </Splide>
    </div>
  );
};
