import React from 'react';

import { VideoPlayButton } from '../../../components/InlineVideo/VideoPlayButton';

type VideoContainerProps = {
  mediaComponent: React.ReactNode;
  disabled: boolean;
  onClick: () => void;
};

export const VideoContainer: React.FC<VideoContainerProps> = ({
  disabled,
  mediaComponent,
  onClick,
}) => {
  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <div className="w-full">{mediaComponent}</div>
      <div className="absolute top-0 right-0 flex items-center justify-center w-full h-full">
        <VideoPlayButton
          disabled={disabled}
          onClick={onClick}
          variant="light"
        />
      </div>
    </div>
  );
};
