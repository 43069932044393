import classNames from 'classnames';
import type React from 'react';

interface Props {
  className?: string;
}

export const AvantArteWordmark: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={classNames('h-14 w-auto', className)}
      viewBox="0 0 1440 360"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Avant Arte logo</title>
      <path
        d="M90 240H114.08C116.832 231.6 119.412 224.4 123.712 211.886H172.044C176.344 224.4 178.752 231.6 181.504 240H206.272C202.316 230.4 195.092 211.886 190.276 199.371L178.408 169.029C171.356 151.029 162.928 129.429 159.66 120H136.44C133.172 129.429 124.916 151.029 117.864 169.029L105.996 199.371C101.18 211.886 93.956 230.4 90 240ZM130.076 193.029L137.3 172.114C141.6 159.771 145.9 147.086 147.448 141.429H148.136C149.512 147.086 154.156 159.771 158.456 172.114L165.68 193.029H130.076Z"
        fill="currentColor"
      />
      <path
        d="M262.746 240H286.138C289.234 230.571 297.146 210.343 304.37 190.8L315.722 160.457C321.398 145.029 327.59 129.6 331.546 120H306.95C304.198 128.914 300.242 140.229 296.63 151.2L284.762 187.714C280.462 200.571 276.678 212.4 275.13 218.057H274.614C273.066 212.4 269.282 200.571 264.982 187.714L253.114 151.2L242.794 120H217.338C221.294 129.6 227.486 145.029 233.162 160.457L244.514 190.8C251.738 210.343 259.65 230.571 262.746 240Z"
        fill="currentColor"
      />
      <path
        d="M342.612 240H366.692C369.444 231.6 372.024 224.4 376.324 211.886H424.656C428.956 224.4 431.364 231.6 434.116 240H458.884C454.928 230.4 447.704 211.886 442.888 199.371L431.02 169.029C423.968 151.029 415.54 129.429 412.272 120H389.052C385.784 129.429 377.528 151.029 370.476 169.029L358.608 199.371C353.792 211.886 346.568 230.4 342.612 240ZM382.688 193.029L389.912 172.114C394.212 159.771 398.512 147.086 400.06 141.429H400.748C402.124 147.086 406.768 159.771 411.068 172.114L418.292 193.029H382.688Z"
        fill="currentColor"
      />
      <path
        d="M499.282 240H521.986C521.642 231.429 521.126 217.543 521.126 201.6V178.457C521.126 165.6 520.954 158.914 520.954 155.657H521.986L581.842 240H601.622C601.278 231.429 600.762 217.543 600.762 201.6V159.771C600.762 143.657 601.278 128.571 601.622 120H579.09C579.434 128.571 579.95 143.657 579.95 159.771V183.429C579.95 195.429 579.95 199.543 580.122 202.8H579.09L519.062 120H499.282C499.626 128.571 500.142 142.114 500.142 159.771V201.6C500.142 217.543 499.626 231.429 499.282 240Z"
        fill="currentColor"
      />
      <path
        d="M644.798 139.2L684.014 138.857C684.014 145.029 684.186 151.714 684.186 158.4V201.6C684.186 216.514 683.67 231.086 683.326 240H706.718C706.374 231.086 705.858 216.514 705.858 201.6V158.4C705.858 151.714 706.03 145.029 706.03 138.857L745.418 139.2V120H644.798V139.2Z"
        fill="currentColor"
      />
      <path
        d="M833.91 240H857.99C860.742 231.6 863.322 224.4 867.622 211.886H915.954C920.254 224.4 922.662 231.6 925.414 240H950.182C946.226 230.4 939.002 211.886 934.186 199.371L922.318 169.029C915.266 151.029 906.838 129.429 903.57 120H880.35C877.082 129.429 868.826 151.029 861.774 169.029L849.906 199.371C845.09 211.886 837.866 230.4 833.91 240ZM873.986 193.029L881.21 172.114C885.51 159.771 889.81 147.086 891.358 141.429H892.046C893.422 147.086 898.066 159.771 902.366 172.114L909.59 193.029H873.986Z"
        fill="currentColor"
      />
      <path
        d="M1060.76 240H1088.79V238.971C1076.75 224.229 1063.51 204.343 1055.25 191.829C1075.03 187.2 1083.29 170.914 1083.29 156.514C1083.29 133.2 1067.29 120 1043.56 120H990.58C990.924 128.914 991.44 143.486 991.44 158.4V201.6C991.44 216.514 990.924 231.086 990.58 240H1013.97C1013.63 231.086 1013.11 216.514 1013.11 201.6V193.029H1031.86C1043.38 211.714 1050.26 222.857 1060.76 240ZM1013.11 174.171V158.4C1013.11 151.714 1013.28 145.029 1013.28 138.857H1040.12C1053.02 138.857 1061.1 144.857 1061.1 156.514C1061.1 168.171 1053.02 174.171 1040.12 174.171H1013.11Z"
        fill="currentColor"
      />
      <path
        d="M1121.32 139.2L1160.53 138.857C1160.53 145.029 1160.7 151.714 1160.7 158.4V201.6C1160.7 216.514 1160.19 231.086 1159.84 240H1183.24C1182.89 231.086 1182.38 216.514 1182.38 201.6V158.4C1182.38 151.714 1182.55 145.029 1182.55 138.857L1221.94 139.2V120H1121.32V139.2Z"
        fill="currentColor"
      />
      <path
        d="M1264.86 240H1350V220.8L1287.56 221.314C1287.56 215.143 1287.39 208.457 1287.39 201.6V188.914H1343.12V170.057H1287.39V158.4C1287.39 151.543 1287.56 144.857 1287.56 138.686L1349.14 139.2V120H1264.86C1265.2 128.914 1265.72 143.486 1265.72 158.4V201.6C1265.72 216.514 1265.2 231.086 1264.86 240Z"
        fill="currentColor"
      />
    </svg>
  );
};
