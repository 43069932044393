import classNames from 'classnames';
import React from 'react';

import { TypographyVariant } from '../../types';
import { Icon } from './Icon';

interface Props {
  size?: TypographyVariant;
  className?: string;
}

export const Spinner: React.FC<Props> = ({ className, size = 'sm' }) => {
  return (
    <Icon
      className={classNames('absolute animate-spin origin-center', className)}
      name="ic_spinner"
      size={size}
    />
  );
};
