import { useTranslation } from 'next-i18next';

import { Image } from '../../../../components/Image';
import { AssetStoryblok } from '../../../../types/generated-storyblok';

type ProductRelatedDigitalProductsProps = {
  image: AssetStoryblok;
  label: string;
  onClick: () => void;
};

export const ProductRelatedDigitalProducts: React.FC<
  ProductRelatedDigitalProductsProps
> = ({ image, label, onClick = () => {} }) => {
  const { t } = useTranslation(['product']);

  return (
    <button
      className="group w-full flex gap-x-3 overflow-hidden text-sm p-3 border border-divider/20 rounded-sm"
      onClick={onClick}
      type="button"
    >
      {image && (
        <span className="w-12 shrink-0">
          <Image
            alt={image?.alt || ''}
            hoverEffect
            shape="rounded"
            src={image.filename}
            width={48}
          />
        </span>
      )}
      <span className="py-1 w-full min-h-[48px] flex items-center">
        <span>
          {label}{' '}
          <span className="underline group-hover:no-underline">
            {t('counterpart.tile.action')}
          </span>
        </span>
      </span>
    </button>
  );
};
