import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { ImageContainer } from './ImageContainer';
import { VideoContainer } from './VideoContainer';

const TEXT_SPAN_CLASSES = {
  '6': 'md:w-8/12 lg:w-6/12',
  '8': 'md:w-8/12',
  '12': 'md:w-full',
};

type AspectRatio =
  | 'threeToFour'
  | 'nineToSixteen'
  | 'twoToThree'
  | 'square'
  | 'none';

type MediaProps =
  | {
      type: 'Image';
      href?: string;
      openLinkInTab?: boolean;
      disabled?: never;
      onClick?: never;
    }
  | {
      type: 'Video';
      href?: never;
      openLinkInTab?: never;
      disabled?: boolean;
      onClick?: () => void;
    };

type Props = MediaProps & {
  mediaComponent: ReactNode;
  author?: string;
  quote?: string;
  title?: string;
  textSpan?: keyof typeof TEXT_SPAN_CLASSES;
  className?: string;
  tag?: string;
  caption?: string;
  isCaptionInsideComponent?: boolean;
  aspectRatio?: AspectRatio;
  cursorPointer?: boolean;
};

export const MediaContainer: React.FC<Props> = ({
  aspectRatio = 'none',
  author,
  caption,
  className,
  cursorPointer = true,
  disabled = false,
  href,
  isCaptionInsideComponent,
  mediaComponent,
  onClick = () => {},
  openLinkInTab,
  quote,
  tag,
  textSpan,
  title,
  type,
}) => {
  const textWidthClass = textSpan && TEXT_SPAN_CLASSES[textSpan];
  const hasInsideCaption = caption && isCaptionInsideComponent;
  const hasOutsideCaption = caption && !isCaptionInsideComponent;
  const hasTextOverlaid = hasInsideCaption || title || quote || author;

  return (
    <>
      <div
        className={classNames(
          'relative w-full overflow-hidden group z-10',
          {
            'aspect-h-3 aspect-w-4': aspectRatio === 'threeToFour',
            'aspect-h-9 aspect-w-16': aspectRatio === 'nineToSixteen',
            'aspect-h-2 aspect-w-3': aspectRatio === 'twoToThree',
            'aspect-h-1 aspect-w-1': aspectRatio === 'square',
            'aspect-none': aspectRatio === 'none',
            'cursor-pointer': cursorPointer,
          },
          className,
        )}
      >
        {type === 'Image' ? (
          <ImageContainer
            href={href}
            mediaComponent={mediaComponent}
            openLinkInTab={openLinkInTab}
          />
        ) : (
          <VideoContainer
            disabled={disabled}
            mediaComponent={mediaComponent}
            onClick={onClick}
          />
        )}

        {hasTextOverlaid && (
          <div
            className={classNames(
              'absolute w-full h-full top-0 left-0 z-20',
              '',
              'pointer-events-none',
            )}
          />
        )}

        <div className="w-full h-full pointer-events-none">
          <div className="absolute break-words w-full bottom-0 left-0 px-4 md:px-5 pb-4 md:pb-5">
            {tag && (
              <div className="inline-block w-full">
                <span className="float-left pt-1 pb-1 pl-2 pr-2 mb-1 text-center text-light bg-dark-30 relative z-30">
                  {tag}
                </span>
              </div>
            )}
            <div
              className={classNames(
                'flex flex-col relative z-30',
                textWidthClass,
              )}
            >
              {hasInsideCaption && (
                <span className="text-light line-clamp-2 md:line-clamp-none">
                  {caption}
                </span>
              )}
              {title && (
                <span className="text-light line-clamp-2 md:line-clamp-none">
                  {title}
                </span>
              )}
              {quote && (
                <q className="text-light line-clamp-2 md:line-clamp-none">
                  {quote}
                </q>
              )}
              {author && <span className="text-light-80">{author}</span>}
            </div>
          </div>
        </div>
      </div>
      {hasOutsideCaption && (
        <div className="mt-3 text-neutral-4">{caption}</div>
      )}
    </>
  );
};
