import React from 'react';

import { Image } from '../../../../components/Image';
import { MultiassetStoryblok } from '../../../../types/generated-storyblok';
import { storyblokImageToLightboxEntry } from '../../../../utils/lightbox';
import { Lightbox } from '../../Lightbox';

type Props = {
  images: MultiassetStoryblok;
};

export const GridGallery: React.FC<Props> = ({ images }) => {
  const lightboxItems = React.useMemo(
    () =>
      images.map((img) => storyblokImageToLightboxEntry(img, false, img.id)),
    [images],
  );

  return (
    <Lightbox items={lightboxItems}>
      {(lightbox) => (
        <div className="grid-section gap-y-6">
          {images.map(({ alt, filename, id, title }, index) => (
            <figure
              key={id}
              className="col-span-6 md:col-span-4 lg:col-span-3 flex flex-col space-y-3"
            >
              <Image
                alt={alt || title || filename}
                hoverEffect
                onClick={() => {
                  lightbox.openOnSlide(id);
                }}
                shape="rounded"
                size={{
                  sm: { span: 6, ratio: 'intrinsic' },
                  md: { span: 4, ratio: 'intrinsic' },
                  lg: { span: 3, ratio: 'intrinsic' },
                }}
                src={filename}
              />

              <figcaption>
                <p className="text-neutral-4 text-xs">
                  {title || `#${index + 1}`}
                </p>
              </figcaption>
            </figure>
          ))}
        </div>
      )}
    </Lightbox>
  );
};
