import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useCallback, useEffect } from 'react';

import BackgroundVideo from '../../../../components/BackgroundVideo';
import { Image } from '../../../../components/Image';
import ScrollAnchor from '../../../../components/ScrollAnchor';
import { ImageSizes } from '../../../../helpers/images';
import { usePrefersReducedMotion } from '../../../../hooks/usePrefersReducedMotion';
import { StoryblokVideo } from '../../../../types/Vimeo';
import { storyblokMediaItemToLightboxEntry } from '../../../../utils/lightbox';
import { Lightbox } from '../../Lightbox';
import { MediaContainer } from '../../MediaContainer';

type Props = {
  heroImageRef: React.RefObject<HTMLAnchorElement>;
  images: Array<
    | ({
        component: 'Video';
        quote?: Quote | undefined;
        _uid: string;
        showCopyright: boolean;
      } & StoryblokVideo)
    | {
        component: 'image';
        image: StoryblokImage;
        caption?: string;
        showCopyright: boolean;
        _uid: string;
      }
  >;
  onClick?: (properties: Record<string, string | number>) => void;
  onSlide?: (index: number) => void;
  resetTrigger?: number;
};

export const ProductImageGallery: React.FC<Props> = ({
  heroImageRef,
  images,
  onClick,
  onSlide = () => {},
  resetTrigger,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const lightboxItems = React.useMemo(
    () => images.map(storyblokMediaItemToLightboxEntry),
    [images],
  );

  const mainRef = React.useRef<Splide>(null);
  const resetSplide = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.go(0);
    }
  }, [mainRef]);

  useEffect(() => {
    resetSplide();
  }, [resetTrigger, resetSplide]);

  useEffect(() => {
    resetSplide();
  });

  return (
    <Lightbox items={lightboxItems}>
      {(lightbox) => (
        <Splide
          ref={mainRef}
          onMoved={(e) => {
            onSlide(e.index);
          }}
          options={{
            label: 'product-image-gallery',
            type: 'fade',

            rewind: true,
            pagination: true,
            mediaQuery: 'min',

            arrows: false,
            focus: 0,
            gap: 0,
            perMove: 1,
            perPage: 1,

            flickPower: 300,
            flickMaxPages: 0.3,
            dragMinThreshold: {
              mouse: 10,
              touch: 30,
            },

            breakpoints: {
              768: {
                destroy: true,
              },
            },
            classes: {
              pagination: 'splide__pagination gap-2 pt-3',
              page: 'splide__pagination__page block w-1.5 h-1.5 bg-neutral-6/10 hover:bg-neutral-6/40 [&.is-active]:bg-neutral-6 rounded-full transition duration-300 ease-out',
            },
          }}
        >
          {images.map((media, index) => {
            const sizes = {
              sm: { ratio: '1/1', span: 12 },
              md: { ratio: '1/1', span: index === 0 ? 6 : 4 },
              lg: { ratio: '1/1', span: index === 0 ? 6 : 4 },
            } as ImageSizes;

            if (media.component === 'image') {
              return (
                <SplideSlide key={media._uid}>
                  <MediaContainer
                    mediaComponent={
                      <div className="relative">
                        <Image
                          alt={media.image.alt}
                          focus={media.image.focus}
                          hoverEffect
                          onClick={() => {
                            lightbox.openOnSlide(index);
                            onClick?.({
                              position: index,
                              title: media.image.title,
                              type: 'image',
                            });
                          }}
                          size={sizes}
                          src={media.image.filename}
                        />
                      </div>
                    }
                    type="Image"
                  />
                  {index === 0 && (
                    <ScrollAnchor
                      ref={heroImageRef}
                      className="absolute"
                      id="heroImage"
                    />
                  )}
                </SplideSlide>
              );
            }

            const videoIsConfigured = media.vimeo.vimeo_oembed !== null;
            const autoplayVideo =
              index === 0 && !prefersReducedMotion && videoIsConfigured;

            return (
              <SplideSlide key={media._uid}>
                {autoplayVideo ? (
                  <button
                    className="group block w-full relative bg-light aspect-[1/1]"
                    onClick={() => {
                      lightbox.openOnSlide(index);
                      onClick?.({
                        position: index,
                        title: media.vimeo.vimeo_oembed.response.title,
                        type: 'video',
                        durationInSeconds:
                          media.vimeo?.vimeo_oembed.response.duration,
                      });
                    }}
                    type="button"
                  >
                    <BackgroundVideo
                      cover={media.coverImage}
                      vimeo={media.vimeo}
                    />
                  </button>
                ) : (
                  <MediaContainer
                    mediaComponent={
                      <Image
                        alt={media.coverImage.alt}
                        eagerLoading={index === 0}
                        focus={media.coverImage.focus}
                        size={sizes}
                        src={media.coverImage.filename}
                      />
                    }
                    onClick={() => {
                      lightbox.openOnSlide(index);
                      onClick?.({
                        position: index,
                        title: media.vimeo.vimeo_oembed.response.title,
                        type: 'video',
                        durationInSeconds:
                          media.vimeo?.vimeo_oembed.response.duration,
                      });
                    }}
                    type="Video"
                  />
                )}
                {index === 0 && (
                  <ScrollAnchor
                    ref={heroImageRef}
                    className="absolute"
                    id="heroImage"
                  />
                )}
              </SplideSlide>
            );
          })}
        </Splide>
      )}
    </Lightbox>
  );
};
