import React, { forwardRef } from 'react';

import { Icon } from '../Icon';
import { FieldWrapper } from './FieldWrapper';

interface Option
  extends Pick<React.OptionHTMLAttributes<HTMLOptionElement>, 'disabled'>,
    Pick<React.ReactElement, 'key'> {
  value: string | number;
}

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  $options: Option[];
  $label?: string;
  $error?: string;
  dataTestid?: string;
  placeholder?: string;
}

export const selectOptionsEnum = (
  enumObj: Record<string, string>,
  toExclude?: string[],
) => {
  return Object.values(enumObj)
    .filter((v) => !toExclude?.includes(v))
    .map((v) => ({ key: v, value: v }));
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      $error,
      $label,
      $options,
      className,
      dataTestid,
      disabled = false,
      id,
      placeholder,
      required = false,
      ...props
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        $disabled={disabled}
        $error={$error}
        $id={id}
        $label={$label}
        className={className}
      >
        <select
          ref={ref}
          data-testid={dataTestid}
          disabled={disabled}
          id={id}
          required={required}
          {...props}
        >
          {placeholder && (
            <option disabled value="">
              {placeholder}
            </option>
          )}
          {$options.map((option) => (
            <option
              key={option.value}
              disabled={option.disabled}
              value={option.value}
            >
              {option.key}
            </option>
          ))}
        </select>
        <Icon className="absolute right-3" name="ic_chevron_down" />
      </FieldWrapper>
    );
  },
);
