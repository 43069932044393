import { FC } from 'react';

import { Icon } from '../Icon';

type Props = {
  disabled: boolean;
  max: number;
  value: number;
  setValue(value: number): void;
};

export const CounterGroup: FC<Props> = ({ disabled, max, setValue, value }) => {
  const decrementDisabled = disabled || value === 1;
  const incrementDisabled = disabled || value === max;

  const handleIncrement = () => {
    if (value !== max) {
      setValue(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  return (
    <div className="border border-divider/20 flex items-center rounded-sm">
      <button
        className="flex items-center justify-center p-1"
        disabled={decrementDisabled}
        onClick={handleDecrement}
        type="button"
      >
        <Icon
          className={decrementDisabled ? 'text-neutral-4' : 'text-neutral-6'}
          name="ic_minus"
          size="md"
        />
      </button>
      <p className="px-2">{value}</p>
      <button
        className="flex items-center justify-center p-1"
        disabled={incrementDisabled}
        onClick={handleIncrement}
        type="button"
      >
        <Icon
          className={incrementDisabled ? 'text-neutral-4' : 'text-neutral-6'}
          name="ic_add"
          size="md"
        />
      </button>
    </div>
  );
};
