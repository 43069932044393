import classNames from 'classnames';
import React from 'react';

import { TypographyVariant } from '../../types';
import { Icon } from './Icon';

interface Props {
  isDark?: boolean;
  size?: TypographyVariant;
  className?: string;
}

export const PulsingDot: React.FC<Props> = ({
  className,
  isDark = false,
  size = 'sm',
}) => (
  <div
    className={classNames(
      'relative',
      isDark ? 'text-active-3' : 'text-active-4 theme-dark:text-active-3',
      className,
    )}
  >
    <Icon name="ic_live" size={size} />
    <Icon
      className="animate-ping absolute top-0 left-0"
      name="ic_live"
      size={size}
    />
  </div>
);
