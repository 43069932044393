import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { Icon } from '../Icon';

type AccordionProps = {
  children: ReactNode;
};

export const Accordion = ({ children }: AccordionProps) => (
  <div className="w-full flex flex-col divide-y divide-divider/20">
    {children}
  </div>
);

type AccordionItemProps = {
  index?: number;
  subject: string;
  isOpen?: boolean;
  children: ReactNode;
  analytics?: () => void;
  anchorClick?: () => void;
};

export const AccordionItem = ({
  analytics = () => {},
  anchorClick,
  children,
  index,
  isOpen = false,
  subject,
}: AccordionItemProps) => {
  const [accordionOpen, setAccordionOpen] = useState(isOpen);

  return (
    <div className="group relative w-full flex flex-col">
      {anchorClick ? (
        <span className="absolute hidden md:block py-4 pr-2 group-hover:-translate-x-8 opacity-0 group-hover:opacity-100 transition duration-300 ease-out">
          <button
            className="shrink-0 w-6 h-6 bg-neutral-1 hover:bg-neutral-2 rounded-sm text-xs flex justify-center items-center text-center cursor-pointer transition duration-300 ease-out"
            onClick={anchorClick}
            type="button"
          >
            #
          </button>
        </span>
      ) : null}
      <motion.button
        className="w-full flex items-start gap-2 py-4 z-20 text-left"
        initial={false}
        onClick={() => {
          setAccordionOpen(!accordionOpen);
          if (accordionOpen) analytics();
        }}
        type="button"
      >
        {index ? (
          <span className="shrink-0 w-6 h-6 bg-neutral-6 rounded-sm text-xs text-white flex justify-center items-center text-center">
            {index}
          </span>
        ) : null}
        <span className="w-full font-bold py-0.5">{subject}</span>
        <span className="shrink-0 py-0.5">
          <Icon
            className={classNames(
              accordionOpen ? 'rotate-[135deg]' : 'rotate-0',
              'transition duration-300 ease-in-out',
            )}
            name="ic_add"
          />
        </span>
      </motion.button>
      <AnimatePresence initial={false}>
        {accordionOpen && (
          <motion.section
            key="content"
            animate="open"
            className="overflow-hidden"
            exit="collapsed"
            initial="collapsed"
            transition={{ duration: 0.3 }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            <div className="flex flex-col gap-y-3 pb-4">{children}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
